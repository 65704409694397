import React from "react";
import './RedSocialBoton.scss';



function RedSocialBoton(props) {
   
    function OpenSocial(e) {
        window.open(props.enlace, '_blank', 'noopener,noreferrer');
        };

    return (
        <button className="RScontainer" onClick={OpenSocial}>
            <img className="logoRS" src={props.icono} alt="icono" />  
            {props.text}
            
        </button>
          
            
    
    );
  }
  
  export default RedSocialBoton;