import React from "react";
import './Evento.scss';
import Boton from "../Boton/Boton";
import {useNavigate} from 'react-router-dom';


function Evento(props) {
    
    const navigate = useNavigate();
    
    return (
      <div className="cardEvento">
        <div className="datosEvento">
           <img className="imagen" src={props.imagen} alt="Fotografia" />
           <p className="nombreEvento">{props.nombre}</p> 
           <p className="datosEventoItem"><strong>CUÁNDO: </strong>{props.fechaHora} </p>
           <p className="datosEventoItem"><strong>DÓNDE: </strong> {props.lugar} </p>
           <p className="datosEventoItem"> <strong>DIRECCIÓN: </strong>{props.direccion}</p>
           <p className="precioEvento">{props.precio}</p>
        </div>

        <div className="boletosControl">
          <Boton text="Obtener Boleto" onClick={() => navigate('/registro')}/>
          <p className="contador">Boletos disponibles: <strong>{props.boletos}</strong> </p>
        </div>     
      </div>
    );
  }
  
  export default Evento; 