import React from "react";
import './EventosValidacion.scss';
import {db} from '../../db';
import { doc, getDoc } from "firebase/firestore";
import {useState,useEffect} from 'react';
import validacion from './img/valido.png';

function EventosValidacion() {

  const [Eventoval, setEventoval] = useState([]);

  useEffect(() => {

    const obtenerDatos = async () => {
        try {
            const data = await db.collection('Eventos').get()
            const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data()}))
            setEventoval(arrayData)  
        } catch (error) {
            console.log(error)
        }
    }
    obtenerDatos()

}, [])


    return (
      Eventoval.map(item =>
      <div key={item.id} className="backgroundEventosValidacion">
        <div className="cardValidacion">
            
              <img className="imagen" src={validacion} alt="BOLETO VALIDO" />
              <p className="nombreEvento">{item.nombre} </p> 
              <p className="datosEventoItem"><strong>CUÁNDO: </strong>{item.fechaHora} </p>
              <p className="datosEventoItem"><strong>DÓNDE: </strong> {item.lugar} </p>
              <p className="datosEventoItem"> <strong>DIRECCIÓN: </strong>{item.direccion}</p>
              
     
        </div>
              
      </div>
      )
    );
  }
  
  export default EventosValidacion;