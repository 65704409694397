import React from "react";
import './Caja2.scss';
import InventarioItem from "../../../../Components/InventarioItem/InventarioItem";

function Caja2() {
  return (
   <div className="backgroundCaja2">
     <InventarioItem nombre ="Bolsa de Arena"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/BolsaDeArena.png"></InventarioItem>
     <InventarioItem nombre ="Maceta de Flores 1"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Maceta1.png"></InventarioItem>
     <InventarioItem nombre ="Maceta de Flores 2"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Maceta2.png"></InventarioItem>
     <InventarioItem nombre ="Maceta de Flores 3"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Maceta3.png"></InventarioItem>
     <InventarioItem nombre ="Bolsa de Aromas"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/BolsaAromas.png"></InventarioItem>
     <InventarioItem nombre ="Stands de Bocina"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/StandsBocina.png"></InventarioItem>
     <InventarioItem nombre ="Stand de Anuncio"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/StandAnuncio.png"></InventarioItem>
     <InventarioItem nombre ="Descanso de Pie"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/DescansoPie.png"></InventarioItem>
     <InventarioItem nombre ="Mezcladora"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Mezcladora.png"></InventarioItem>
     <InventarioItem nombre ="Extension HeavyDuty"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/ExtensionHeavyDuty.png"></InventarioItem>
     <InventarioItem nombre ="Conexion Splitter"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/ConexionSplitter.png"></InventarioItem>
     <InventarioItem nombre ="Extension Generica"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/ExtensionGenerica.png"></InventarioItem>
     <InventarioItem nombre ="Anuncio para Contribuciones"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/AnuncioContribuciones.png"></InventarioItem>
     <InventarioItem nombre ="Caja para Contribuciones"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CajaContribuciones.png"></InventarioItem>

     <InventarioItem nombre ="Caja Central"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CajaCentral.png"></InventarioItem>
     <InventarioItem nombre ="Cubierta de Caja Central"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CubiertaCajaCentral.png"></InventarioItem>

   </div>
  );
}

export default Caja2;