import React from "react";
import './Caja1.scss';
import InventarioItem from "../../../../Components/InventarioItem/InventarioItem";

function Caja1() {
  return (
   <div className="backgroundCaja1">
      <InventarioItem nombre ="Lampara de Atril"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/LamparaAtril.png"></InventarioItem>
      <InventarioItem nombre ="Bolsa de Arena"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/BolsaDeArena.png"></InventarioItem>
      <InventarioItem nombre ="Carpeta de Partituras 1"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CarpetaPartituras1.png"></InventarioItem>
      <InventarioItem nombre ="Carpeta de Partituras 2"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CarpetaPartituras2.png"></InventarioItem>
      <InventarioItem nombre ="Bolsa de Arena"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/BolsaDeArena.png"></InventarioItem>
      <InventarioItem nombre ="Lampara de Piso"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/LamparaPiso.png"></InventarioItem>
      <InventarioItem nombre ="Lampara de Pared" imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/LamparaPared.png"></InventarioItem>
      <InventarioItem nombre ="Conector de Lampara de Pared"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/ConectorLamparaPared.png"></InventarioItem>
     
      <InventarioItem nombre ="Bolsa de Montaje Lamparas Pared"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/BolsaMontajeLamparasPared.png"></InventarioItem>
      <InventarioItem nombre ="Lampara Escenario"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/LamparaEscenario.png"></InventarioItem>
      <InventarioItem nombre ="Lamparas de Ambiente"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/LamparasAmbiente.png"></InventarioItem>
      <InventarioItem nombre ="Carpeta de Partituras 2"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CarpetaPartituras2.png"></InventarioItem>


   </div>
  );
}

export default Caja1;