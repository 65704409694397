import React from "react";
import './CajaAudio.scss';
import InventarioItem from "../../../../Components/InventarioItem/InventarioItem";

function CajaAudio() {
  return (
   <div className="backgroundCajaAudio">
     <InventarioItem nombre ="Cable Audio-RCA.png"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CableAudio-RCA.png"></InventarioItem>
     <InventarioItem nombre ="Audifonos de Monitor"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/AudifonosMonitor.png"></InventarioItem>
     <InventarioItem nombre ="Extension Audifonos DJ"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/ExtensionAudifonosDJ.png"></InventarioItem>
     <InventarioItem nombre ="Extension de Audifonos para Monitor"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/ExtensionAudifonosMonitor.png"></InventarioItem>
     <InventarioItem nombre ="Monitor-Adaptadores"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Monitor-Adaptadores.png"></InventarioItem>
     <InventarioItem nombre ="Cargador de Monitor"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CargadorMonitor.png"></InventarioItem>
     <InventarioItem nombre ="Microfono Vocalista"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/MicrofonoVocalista.png"></InventarioItem>
     <InventarioItem nombre ="Microfono Condensador de Guitarra"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/MicrofonoCondensadorGuitarra.png"></InventarioItem>
     <InventarioItem nombre ="Microfono Dinamico"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/MicrofonoDinamico.png"></InventarioItem>
     
   </div>
  );
}

export default CajaAudio;