import React from "react";
import Evento from "../../Components/Evento/Evento";
import './Eventos.scss';
import {db} from '../../db';
import { doc, getDoc } from "firebase/firestore";
import {useState,useEffect} from 'react';

function Eventos() {


  const [Eventodb, setEventodb] = useState([]);

  useEffect(() => {

    const obtenerDatos = async () => {
        try {
            const data = await db.collection('Eventos').get()
            const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data()}))
            setEventodb(arrayData)  
        } catch (error) {
            console.log(error)
        }
    }
    obtenerDatos()

}, [])



  return (
    
    Eventodb.map(item =>
    <div key={item.id} className="backgroundEventos">   
       
        <Evento nombre={item.nombre} 
        fechaHora={item.fechaHora} lugar={item.lugar} direccion={item.direccion}
        imagen={item.imagen}
        boletos={item.boletos} precio={item.precio}/>
        
    </div>
    )
   
  );
}

export default Eventos;