import React from "react";
import './InventarioItem.scss';

function InventarioItem(props) {
  return (
   <div className="backgroundInventarioItem">
       <p className="itemNombre">{props.nombre}</p>
       <img className="imagen" src={props.imagen} alt="ITEM" />

   </div>
  );
}

export default InventarioItem;