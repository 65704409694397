import React from "react";
import './Registro.scss';
import Boton from "../../Components/Boton/Boton";
import { useState} from "react";
import { useForm } from "react-hook-form";
import {db} from '../../db';
import {useNavigate} from 'react-router-dom';
// import getMyDoc from "../../Wrappers/getMyDoc";





function Registro() {

    const { register, handleSubmit, formState: { errors }} = useForm();
    const [data, setData] = useState("");
    const navigate = useNavigate();
    
    let EventInfo =  db.collection("Eventos").doc("Main").get().then(doc => {
        EventInfo=doc.data() 
    })
    

    
    
    
    const onSubmit = (data, e) => {
        setData(data);
        addFan(data); 
        updateTickets();
        getTicket(); 
        navigate('/contacto')
    };
      

    const getTicket=(e) => {
        window.open("https://sonecitolindo.s3.amazonaws.com/TicketDigital.png", '_blank', 'noopener,noreferrer');
        };


    const updateTickets=(e) => {
        db.collection("Eventos")
           .doc("Main")
            .set(
                {
                    boletos: EventInfo.boletos-1
                },
                { merge: true }
        ); 
    }


    const addFan=(props) => {
        db.collection("Fans").add({
            email: props.email,
            phone: props.phone,
        })
    }
        

  return (
   <div className="backgroundRegistro">
     
    
     <form className="cardRegistro" onSubmit={handleSubmit((onSubmit) )}>
         <p className="labelRegistro">Ingresa tus datos para obtener tu boleto</p>
        
         <input className="input" {...register("email" , { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ , required: true})} placeholder="Correo Electronico"  />
        
         <input className="input" {...register("phone", { minLength: 10, maxLength: 10 ,required: true})} placeholder="Numero de Teléfono Movil" />
         <Boton type="submit" text="Obtener Boleto"  />
         {errors.phone && <p className="labelerror">Ingresa un telefono válido</p>}
         {errors.email && <p className="labelerror">Ingresa un correo electronico válido</p>}
      
    </form>
      

    
          
   </div>
  );
}

export default Registro;





