import React from "react";
import './Contacto.scss';
import RedSocialBoton from "../../Components/RedSocialBoton/RedSocialBoton";
import facebook from './img/icono-facebook.png';
import instagram from './img/icono-instagram.png';
import youtube from './img/icono-youtube.png';
import whatsapp from './img/icono-whatsapp.png';

function Contacto() {

  return (
    <div className="backgroundContacto">
      <div className="panel">
          <p className="ActionCall"> Siguenos en redes sociales </p>
          <RedSocialBoton enlace="https://facebook.com/sonecitolindo" text="/SonecitoLindo" icono={facebook} > </RedSocialBoton>
          <RedSocialBoton enlace="https://www.instagram.com/sonecitolindo/" text="/SonecitoLindo" icono={instagram} > </RedSocialBoton>
          <RedSocialBoton enlace="https://www.youtube.com/channel/UC9iM1xTSG9B4a7NcH4H3-xw" text="/SonecitoLindo" icono={youtube} > </RedSocialBoton> 
          {/* <RedSocialBoton enlace="tel:6563500404" text="(656) 350 04 04" icono={whatsapp} > </RedSocialBoton>                    */}
      </div>
    </div>
  );
}

export default Contacto;