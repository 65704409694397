import React from "react";
import './Musica.scss';
import logo from './folklore.png';
import MusicoCard from "../../Components/MusicoCard/MusicoCard";
import ReactPlayer from 'react-player'
import fotoEunice from './fotoEunice.jpg';
import fotoLorenzo from './fotoLorenzo.jpg';


function Musica() {
  return (
    <>
   <div className="backgroundMusica">
      <div className="player-wrapper">
          <ReactPlayer  volume ={1} muted width="100%"  className="react-player" url='https://sonecitolindo.s3.amazonaws.com/VideoPromo.mp4' autoPlay playsinline playing loop controls ></ReactPlayer>
      </div>
         <div className ="content">
            <div className="cover" >
              <img className="arte" src={logo} alt="Logo" />
            </div>

            <div className="musicos">
                <MusicoCard  foto = {fotoEunice} musico="Eunice" nombre ="Eunice, Voz" estiloTitulo ="estiloTitulo1" tituloDescripcion="Misticismo Prehispanico" descripcion ={`Para los pueblos originarios de México, la comunión con la naturaleza es parte vital de su cosmovisión. \nLa música forma parte de sus rituales y representa el canto de la tierra y de los dioses. \nLa tradición se transmite entre generaciones de manera oral, de voz en voz, preservando y manifestando el sentir del pueblo.`}>
                </MusicoCard>

                <MusicoCard  foto = {fotoLorenzo} musico="Lorenzo" nombre ="Lorenzo, Guitarra" estiloTitulo ="estiloTitulo2" tituloDescripcion="Mestizaje Español" descripcion ={`A diferencia de los Españoles de clase alta que llegan a la Nueva España durante la conquista y se trasladan a la capital, muchos Españoles originarios de Andalucía se quedan a probar suerte en Veracruz, el puerto de entrada al virreinato. \nLos andaluces traen consigo la Guitarra, instrumento esencial de su música: El Flamenco. \nAndaluces e indígenas Huastecos de la región, amalgaman costumbres, fiestas y cantos, dando origen a la semilla de la Música Mexicana: El Huapango.`}>
                </MusicoCard>
            </div>
        </div>
          
   </div>
   </>
  )
}

export default Musica;