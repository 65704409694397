import React from "react";
import { NavLink } from "react-router-dom";
import './Navigation.scss';

function Navigation() {
  return (
    <div className="header">
      <nav className="navigation">
                <NavLink className="item" to="/">
                  Inicio
                </NavLink>
             
                <NavLink className="item2" to="/musica">
                  Música
                </NavLink>
             
                <NavLink className="item3" to="/eventos">
                  Eventos
                </NavLink>
             
                <NavLink className="item4" to="/contacto">
                  Contacto
                </NavLink>
      </nav>
    </div>
  );
}

export default Navigation;