import React from "react";
import './Home.scss';
import logo from './img/Logo.png';
import Footer from "../../Components/Footer/Footer";




function Home() {
  return (
   <>
      <div className="backgroundHome">
         <img className="logo" src={logo} alt="Logo" />;
     </div> 
     <Footer/>
  
  </>
   
  );
}

export default Home;