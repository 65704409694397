import React from "react";
import './AgregarFans.scss';
import Boton from "../../Components/Boton/Boton";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {db} from '../../db';





function AgregarFans() {

    const { register, handleSubmit, reset,  formState: { errors}} = useForm();
    const [data, setData] = useState("");
    
    
    
    const onSubmit = (data, e) => (setData(JSON.parse(JSON.stringify(data))),addFan(data),reset());
      
    
        // React.useEffect(() => {
        //     if (formState.isSubmitSuccessful) {
        //     reset({ something: '' });
        //      }
        // }, [formState, submittedData, reset]);
    

    function addFan(props) {
        db.collection("Fans").add({
            email: props.email,
            phone: props.phone,
        })
    }
        

  return (
   <div className="backgroundAgregarFans">
     
    
     <form className="cardAgregarFans" onSubmit={handleSubmit((onSubmit) )}>
         <p className="labelAgregarFans">Ingresa los datos a guardar</p>
        
         <input className="inputAgregarFans" {...register("email" , { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ , required: true})} placeholder="Correo Electronico"  />
        
         <input className="inputAgregarFans" {...register("phone", { minLength: 10, maxLength: 10 ,required: true})} placeholder="Numero de Teléfono Movil" />
         <Boton type="submit" text="Guardar"  />
         {errors.phone && <p className="labelerrorAgregarFans">Ingresa un telefono válido</p>}
         {errors.email && <p className="labelerrorAgregarFans">Ingresa un correo electronico válido</p>}
      
    </form>
      

    
          
   </div>
  );
}

export default AgregarFans;





