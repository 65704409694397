import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./Components/Navigation/Navigation";
import Home from "./Views/Home/Home";
import Eventos from "./Views/Eventos/Eventos";
import EventosValidacion from "./Views/EventosValidacion/EventosValidacion";
import Musica from "./Views/Musica/Musica";
import Contacto from "./Views/Contacto/Contacto";
import Caja1 from "./Views/EventosSetup/Folklore/Caja1/Caja1";
import Caja2 from "./Views/EventosSetup/Folklore/Caja2/Caja2";
import BolsaAudio from "./Views/EventosSetup/Folklore/BolsaAudio/BolsaAudio";
import Registro from "./Views/Registro/Registro";
import ScrollTop from "./Wrappers/ScrollTop";
import AgregarFans from "./Views/AgregarFans/AgregarFans";
import CajaAudio from "./Views/EventosSetup/Folklore/CajaAudio/CajaAudio";
import BolsaExtras from "./Views/EventosSetup/Folklore/Bolsa de Extras/BolsaExtras";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router >
    <Navigation />
    <ScrollTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/eventos" element={<Eventos />} /> 
        <Route path="/eventosvalidacion" element={<EventosValidacion />} /> 
        <Route path="/musica" element={<Musica />} /> 
        <Route path="/contacto" element={<Contacto />} /> 
        <Route path="/eventossetup/folklore/caja1" element={<Caja1 />} /> 
        <Route path="/eventossetup/folklore/caja2" element={<Caja2 />} /> 
        <Route path="/eventossetup/folklore/BolsaAudio" element={<BolsaAudio />} /> 
        <Route path="/registro" element={<Registro />} /> 
        <Route path="/agregarFans" element={<AgregarFans />} /> 
        <Route path="/eventossetup/folklore/bolsaExtras" element={<BolsaExtras />} /> 
        <Route path="eventossetup/folklore/cajaaudio" element={<CajaAudio />} /> 
      </Routes>
    </ScrollTop>
  </Router>,

  
);