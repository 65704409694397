import React from "react";
import './BolsaExtras.scss';
import InventarioItem from "../../../../Components/InventarioItem/InventarioItem";

function BolsaExtras() {
  return (
   <div className="backgroundBolsaExtras">
     <InventarioItem nombre ="Stand Microfono 1"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/StandMicrofono1.png"></InventarioItem>
     <InventarioItem nombre ="Stand Microfono 2"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/StandMicrofono2.png"></InventarioItem>
     <InventarioItem nombre ="Atril"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Atril.png"></InventarioItem>
     <InventarioItem nombre ="Stand Lampara de Escenario"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/StandLamparaEscenario.png"></InventarioItem>
     <InventarioItem nombre ="Stand de Guitarra"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/StandGuitarra.png"></InventarioItem>
     <InventarioItem nombre ="Cubierta Stand de Guitarra"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CubiertaStandGuitarra.png"></InventarioItem>
     <InventarioItem nombre ="Caja de Retoques"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CajaRetoques.png"></InventarioItem>
     <InventarioItem nombre ="Marco de Escenario"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/MarcoEscenario.png"></InventarioItem>
     <InventarioItem nombre ="Bugambilias 1"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Bugambilias1.png"></InventarioItem>
     <InventarioItem nombre ="Bugambilias 2"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/Bugambilias2.png"></InventarioItem>
     <InventarioItem nombre ="Lazos de Transporte"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/LazosTransporte.png"></InventarioItem>
     <InventarioItem nombre ="Manteles"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/MarcoEscenario.png"></InventarioItem>
     
   </div>
  );
}

export default BolsaExtras;