import React from "react";
import './Footer.scss';
import iconoInstagram from './img/icono-instagram-blanco.png'
import iconoYT from './img/icono-youtube-blanco.png'
import iconoFacebook from './img/icono-facebook-blanco.png'


function Footer() {
  return (
    <div className="footer">
        <nav className="FooterNav">
            <a className = "BotonRed" href="https://facebook.com/sonecitolindo" >
                <img className = "iconoRed" src={iconoFacebook}  alt="facebook" />
           </a>

           <a className = "BotonRed" href="https://www.instagram.com/sonecitolindo/">
                <img className = "iconoRed"src={iconoInstagram}  alt="Instagram" />
           </a>

           <a className = "BotonRed" href="https://www.youtube.com/channel/UC9iM1xTSG9B4a7NcH4H3-xw">
                <img className = "iconoRed" src={iconoYT}  alt="youtube" />
           </a>
           
        </nav> 
       
    </div>
  );
}

export default Footer;