import React from "react";
import './MusicoCard.scss';

function MusicoCard(props) {
    return (
      <div className="cardMain">
         <div className={props.musico}>
               <img className="fotoMusico" src={props.foto} alt="Logo" />
               <p className="nombreMusico">{props.nombre}</p>
         </div>
    
         <div className="descripcionMusico">
         <p className={props.estiloTitulo}>{props.tituloDescripcion}</p>
         <p className="descripcion"> {props.descripcion}</p>
         </div>

      </div>
    );
  }
  
  export default MusicoCard;