import React from "react";
import './BolsaAudio.scss';
import InventarioItem from "../../../../Components/InventarioItem/InventarioItem";

function BolsaAudio() {
  return (
   <div className="backgroundBolsaAudio">
     <InventarioItem nombre ="Caja de Audio"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CajaAudio.png"></InventarioItem>
     <InventarioItem nombre ="Strip Electrico"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/StripElectrico.png"></InventarioItem>
     <InventarioItem nombre ="Cargador de Mezcladora"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CargadorMezcladora.png"></InventarioItem>
     <InventarioItem nombre ="Cable Microfono XLR"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CableMicrofono.png"></InventarioItem>
     <InventarioItem nombre ="CableTRS-Bocina"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CableTRS-Bocina.png"></InventarioItem>
     <InventarioItem nombre ="CableTRS-Escenario"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/CableTRS-Escenario.png"></InventarioItem>
     <InventarioItem nombre ="Conector de Bocina.png"imagen="https://sonecitolindo.s3.amazonaws.com/Inventario/ConectorBocina.png"></InventarioItem>
    
   </div>
  );
}

export default BolsaAudio;